import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space, COLOR_CONSTANTS } from 'theme'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 2000px ${transparentize(0.5, COLOR_CONSTANTS.SALUTE)};
  padding: ${space.xxxl} ${space.m};
`

const Hero = ({ children }) => <Wrapper>{children}</Wrapper>

Hero.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Hero
