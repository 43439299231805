import styled from 'styled-components'

const BgImg = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: cover;
  z-index: -1;
`

export default BgImg
