import React, { Fragment } from 'react'
import App from 'App'
import styled from 'styled-components'
import { colors, radius, COLOR_CONSTANTS } from 'theme'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { H1, H3, H2, Text } from 'components/Typography'
import Container from 'components/Container'
import CtaHero from 'components/CtaHero'
import Footer from 'components/Footer'
import Button from 'components/Button'
import ImageText from 'routes/About/ImageText'
import Hero from 'components/Hero'
import BgImg from 'components/BgImage'
import badges1 from 'static/svg/badges_1.svg'
import bgImage from 'static/images/bg_celebration.svg'
import Link from 'components/Link'
import LocalImage from 'components/LocalImage'
import bgBadge from '../static/images/bg_badge.jpg'
import badgeLogo from '../static/images/badge.png'
import diary from '../static/svg/diary_red.svg'
import social from '../static/svg/social_red.svg'
import list from '../static/svg/list_red.svg'
import content from '../static/svg/content_red.svg'
import badgeFilled from '../static/svg/badge_filled_red.svg'
import rankUp from '../static/svg/rank_up_red.svg'
import recommended from '../static/svg/recommend_red.svg'
import increase from '../static/svg/increase_red.svg'
import badge from '../static/svg/badge_red.svg'
import hashtag from '../static/svg/hashtag_red.svg'

const StyledH3 = styled(H3)`
  opacity: 0.8;
`

const LocalImageWrapper = styled(Flex)`
  min-width: 18px;
  min-height: 18px;
`

const StyledFlexImageContainer = styled(Flex)`
  background: url(${({ background }) => background});
  background-position: center;
  background-repeat: no-repeat;
`

const StyledFlex = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  box-shadow: 0px 4px 20px rgba(39, 40, 49, 0.1);
  border-radius: ${radius.l};
`

const IMAGE_TEXT_CONTENT = [
  {
    title: 'What is a verified badge?',
    body:
      'We can all agree the digital agency landscape is a very saturated market right now - with everyone claiming they are an expert. With verification, we ensure all agencies’ experience and success are stamped with a seal of trust and confirmation.',
    image: badgeLogo,
    button: 'Get verified',
    buttonLink: '/signup',
  },
  {
    title: 'How Our Verification Process Works',
    body:
      "Agency Vista is not a directory but something more powerful. Our algorithm utilizes an agency's profile information to connect businesses and agencies with the most qualified leads. Part of our validation process requires our Agency Vista members to connect their social media accounts, onboard and verify clients, and invite team members. Once an agency has been verified, they will receive access to the badge on their account.",
    image: badges1,
    button: 'Watch Agency Verification Process Video',
    buttonLink: '/search',
  },
]

const benefitsList = [
  [
    {
      title: 'Higher Search Ranking',
      description: `Verified profiles will receive priority positioning and ranking within our search. Learn more about how we authenticate information <a href="/trust" target="_blank" rel="noreferrer noopener">here</a>.`,
      image: rankUp,
    },
    {
      title: 'List Placements',
      description:
        'Agency Vista has developed an algorithm that utilizes data points to measure the credibility and trustworthiness of each of our verified members. Browse lists to identify a credible digital marketing agency that best suits your business needs.',
      image: list,
    },
    {
      title: 'Verified Profile Badge',
      description:
        'Standout by gaining an awesome verified badge on your Agency Vista profile, further establishing social proof and credibility.',
      image: badgeFilled,
    },
    {
      title: 'Verified Badge for External Use',
      description:
        'Post a top companies badge on your website or in your email signature as social proof that your clients say you deliver high-quality results.',
      image: badge,
    },
  ],

  [
    {
      title: 'Complimentary Review Recruitment',
      description:
        'Agency Vista wants to help our verified service providers gain the positive credibility they deserve from current and previous customers through complimentary review recruitment. ',
      image: diary,
    },
    {
      title: 'Guest Blogging',
      description:
        'Spread your brand’s message and win the trust of your audience through guest blogging opportunities. This is a great way to reach a new audience and generate more traffic to your agency profile. ',
      image: social,
    },
  ],

  [
    {
      title: 'Recommended Agencies Removed',
      description: 'Recommended agencies will be removed from the bottom of all verified profiles. ',
      image: recommended,
    },
    {
      title: 'Increased Profile Exposure',
      description: 'Be showcased on high traffic pages outside of search to drive more leads to your agency.',
      image: increase,
    },
  ],
  [
    {
      title: 'Content Amplifier',
      description: `Verified agencies can amplifier their content exposure by tagging Agency Vista and getting their content reshared onto our channels.`,
      image: content,
    },
    {
      title: '#FeatureFriday and #TransformationTuesday',
      description:
        'As a thought industry leader your agency has the opportunity to be featured in a blog post that is shared across many channels. ',
      image: hashtag,
    },
  ],
]

const Badge = () => (
  <App>
    <SEO
      title="Get Verified Badge"
      description="With verification, we ensure all agencies’ experience and success are stamped with a seal of trust and confirmation."
      path="/badge/"
      image="/img/scale_your_social_agency.jpg"
    />
    <Flex mt="xl" justifyContent="center" minHeight="250px" position="relative" overflow="hidden">
      <Box position="absolute" width="100%" height="100%">
        <StyledFlexImageContainer
          width="100%"
          height="100%"
          background={bgImage}
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <LocalImage source={badgeLogo} width="250px" heigth="190px" />
        </StyledFlexImageContainer>
      </Box>
    </Flex>
    <Container maxWidth="1080px" mt="xl">
      <H2 textAlign="center">Earn more trust and credibility through verification</H2>
      <Box mt="m" maxWidth="700px" mx="auto">
        <Text fontSize="xl" textAlign="center" color="ternaryText" opacity="0.9">
          The Agency Vista badge is awarded to companies that meet the highest standards of performance and service. If
          you’ve got a badge, it tells your customers and potential clients you’re among the best at what you do.
        </Text>
      </Box>
      <Flex mt="m" justifyContent="center">
        <Button.Primary as={Link} href="/signup" width="fit-content">
          <Text>Get Verified</Text>
        </Button.Primary>
      </Flex>
      <Box mt="xxl">
        {IMAGE_TEXT_CONTENT.map((content, index) => (
          <Box key={content.title} mb="xxl">
            <ImageText isReversed={index % 2 !== 0} isSecondBubbleVisible={index % 2 === 0} {...content} />
          </Box>
        ))}
      </Box>
      <H2 mt="xl" textAlign="center">
        Amplify your Agency’s success by becoming verified and gain access to a multitude of growth solutions.
      </H2>
      {/* showing for desktop part with benefits */}
      <Flex mt="xl" justifyContent="center" flexDirection="column" display={{ mobile: 'none', desktop: 'block' }}>
        {benefitsList.map((item, index) => (
          <Grid gridTemplateColumns={['1fr', 'repeat(2, 1fr)']} key={index} minHeight="192px" gridGap="l">
            {item.map(({ title, description, image }) => (
              <StyledFlex my="m" pb="m" flexDirection="column" backgroundColor="#ffffff" key={title}>
                <Flex alignItems="center" px="s" p="m">
                  <LocalImageWrapper justifyContent="center">
                    <LocalImage src={image} width="24px" height="24px" />
                  </LocalImageWrapper>
                  <Text ml="m" as="span" fontSize="xl" fontWeight="bold">
                    {title}
                  </Text>
                </Flex>
                <Text
                  key={description}
                  fontSize="m"
                  px="m"
                  pb="l"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </StyledFlex>
            ))}
          </Grid>
        ))}
      </Flex>
      {/* showing for mobiles part with benefits */}
      <Grid gridTemplateColumns={['1fr', 'repeat(3, 1fr)']} gridGap="l" display={{ mobile: 'block', desktop: 'none' }}>
        {benefitsList.map((item, index) => (
          <Fragment key={index}>
            {item.map(({ title, description, image }) => (
              <Flex my="m" pb="m" flexDirection="column" backgroundColor="#ffffff" key={title}>
                <Flex alignItems="center" px="s" p="m">
                  <LocalImageWrapper justifyContent="center">
                    <LocalImage src={image} width="24px" height="24px" />
                  </LocalImageWrapper>
                  <Text ml="m" as="span" fontSize="xl" fontWeight="bold">
                    {title}
                  </Text>
                </Flex>
                <Text
                  key={description}
                  fontSize="m"
                  px="m"
                  pb="l"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </Flex>
            ))}
          </Fragment>
        ))}
      </Grid>
    </Container>
    <Box my="xl" backgroundColor={colors.primary}>
      <Container maxWidth="1080px">
        <CtaHero />
      </Container>
    </Box>
    <Footer />
  </App>
)

export default Badge
