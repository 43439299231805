import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { position } from 'styled-system'
import { Flex, Box } from 'components/Layout'
import { H2, Text } from 'components/Typography'
import Button from 'components/Button'
import shape1 from '../../static/images/shape1.png'
import shape2 from '../../static/images/shape2.png'
import LocalImage from 'components/LocalImage'

const BgImage = styled(LocalImage)`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  height: auto;
  z-index: -1;
  position: absolute;
  ${position};
`

const StyledImage = styled.img`
  pointer-events: none;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const ImageText = ({ title, body, image, button, buttonLink, template, isReversed, isSecondBubbleVisible }) => (
  <Flex
    flexDirection={['column', 'row']}
    justifyContent={['center', 'space-between']}
    alignItems="center"
    flexWrap="wrap"
  >
    <Flex order={[1, isReversed ? 2 : 1]} flexDirection="column" maxWidth="430px">
      <H2>{title}</H2>
      <Text
        mt="m"
        color="ternaryText"
        fontSize="xl"
        opacity={0.9}
        lineHeight="xl"
        dangerouslySetInnerHTML={{ __html: body }}
      />
      {button && (
        <Box mt="m" as="a" href={buttonLink}>
          <Button.Primary>
            <Text fontSize="s">{button}</Text>
          </Button.Primary>
        </Box>
      )}
      {template && [template]}
    </Flex>
    <Flex
      width="100%"
      maxWidth="500px"
      order={[2, isReversed ? 1 : 2]}
      mt={['l', '0']}
      justifyContent="center"
      position="relative"
    >
      <StyledImage src={image} alt={title} />
      <BgImage src={shape1} left={-60} bottom={-60} maxWidth="220px" />
      {isSecondBubbleVisible && (
        <BgImage
          src={shape2}
          top={-60}
          right={[0, -60]}
          maxWidth="300px"
          display={{ mobile: 'none', tablet: 'block' }}
        />
      )}
    </Flex>
  </Flex>
)

ImageText.defaultProps = {
  isReversed: false,
  isSecondBubbleVisible: false,
  button: '',
  buttonLink: '#',
}

ImageText.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  isReversed: PropTypes.bool,
  isSecondBubbleVisible: PropTypes.bool,
  button: PropTypes.string,
  buttonLink: PropTypes.string,
}

export default ImageText
